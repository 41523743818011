@import "./player.css";
@import "./vendor/embla-carousel-react.css";
@import "./vendor/apple-pay.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Disable smooth scrolling when users have prefers-reduced-motion enabled
 */
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }

  strong {
    @apply font-bold;
  }

  u {
    @apply underline;
  }
}

@layer components {
  .typography-display-1 {
    @apply font-display font-semibold text-[48px] laptop:text-[56px] leading-[1.4];
  }

  .typography-display-2 {
    @apply font-display font-semibold text-[40px] laptop:text-[48px] leading-[1.4];
  }

  .typography-display-3 {
    @apply font-display font-semibold text-[32px] laptop:text-[40px] leading-[1.4];
  }

  .typography-heading-1 {
    @apply font-display font-semibold text-3xl laptop:text-4xl leading-[1.4];
  }

  .typography-heading-2 {
    @apply font-display font-semibold text-2xl laptop:text-3xl leading-[1.4];
  }

  .typography-heading-3 {
    @apply font-display font-semibold text-xl laptop:text-2xl leading-[1.4];
  }

  .typography-heading-4 {
    @apply font-display font-semibold text-lg laptop:text-xl leading-[1.4];
  }

  .typography-heading-5 {
    @apply font-display font-medium text-md laptop:text-lg leading-[1.4];
  }

  .typography-heading-6 {
    @apply font-display font-medium text-sm laptop:text-md leading-[1.4];
  }

  .typography-body-default-regular {
    @apply font-body font-normal text-sm laptop:text-md leading-[1.4];
  }

  .typography-body-default-bold {
    @apply font-body font-bold text-sm laptop:text-md leading-[1.4];
  }

  .typography-body-small-regular {
    @apply font-body font-normal text-xs laptop:text-sm leading-[1.4];
  }

  .typography-body-small-bold {
    @apply font-body font-bold text-xs laptop:text-sm leading-[1.4];
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .mask-gradient-r {
    -webkit-mask-image: linear-gradient(to right, black -30%, transparent 85%);
    mask-image: linear-gradient(to right, black -30%, transparent 85%);
  }

  .mask-gradient-l {
    -webkit-mask-image: linear-gradient(to left, black -30%, transparent 85%);
    mask-image: linear-gradient(to left, black -30%, transparent 85%);
  }
}
